import React from "react";
import { Button, Form, Modal } from "react-bootstrap";

interface SelectCenterProps {
    show: boolean;
    setSelectedCenter: (selectedCenter: string) => void;
}

export default function SelectCenter(props: SelectCenterProps) {

    const [selectedCenter, setSelectedCenter] = React.useState<string | undefined>(undefined);

    const handleClose = () => {
        if(selectedCenter) {
            props.setSelectedCenter(selectedCenter);
        }
    }

    const handleSelectedCenterChanged = (event: React.ChangeEvent<HTMLSelectElement>) => {
        if (!event.target.value) {
            alert("Please select the center");
            return;
        }

        if (window.confirm(`You have selected ${event.target.value}. Please confirm before proceeding.`) === false) {
            return;
        }

        setSelectedCenter(event.target.value);
    }

    return (<Modal show={props.show} onHide={handleClose}>
        <Modal.Header closeButton>
            <Modal.Title>Select the center</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form.Control as="select" value={selectedCenter} onChange={handleSelectedCenterChanged}>
                <option></option>
                <option>Adelaide</option>
                <option>Berri</option>
                 {/*<option>Alice Springs</option>
                <option>Albury</option>
                <option>Ballarat</option>
                <option>Broken Hill</option>
                <option>Cairns</option>
                <option>Darwin</option>
                <option>Gatton</option>
                <option>Geelong</option>
                <option>Gold Coast</option>
                <option>Griffith</option>
                <option>Hobart</option>
                <option>Launceston</option>
                <option>Mildura</option>
                <option>Newcastle</option>
                <option>Brisbane Mandir</option>
                <option>Brisbane North</option>
                <option>Canberra</option>
                <option>Melbourne East</option>
                <option>Melbourne South</option>
                <option>Melbourne West</option>
                <option>Melbourne</option>
                <option>Perth Mandir</option>
                <option>Perth South</option>
                <option>Shepparton</option>
                <option>Sunshine Coast</option>
                <option>Sydney</option>
                <option>Toowoomba</option>
                <option>Townsville</option>
                <option>Wagga Wagga</option>
                <option>NZ-Auckland</option>
                <option>NZ-Christchurch</option>
                <option>NZ-Hamilton</option>
                <option>NZ-Rotorua</option>
                <option>NZ-Wellington</option>*/}
            </Form.Control>
        </Modal.Body>
        <Modal.Footer>  
            <Button variant="primary" onClick={handleClose}>
                Save
          </Button>
        </Modal.Footer>
    </Modal>);
}